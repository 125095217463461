import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Modal,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import dataList from '../../constants/dataList';
import PhoneNumberInput from '../../components/phoneNumberInput';
import ZipCodeInput from '../../components/zipCodeInput';
import config from '../../config';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';
import { FormatCurrency } from '../../hooks/functions/FormatCurrency';
import ReactRecaptchaConnected from '../../components/reCaptcha';
import Api from '../../redux/lib/api';
import ShippingAddressComponent from './shipping-address.component';
import { Address } from './address.type';
import { v4 as uuidv4 } from 'uuid';
import ValidateAddressModal from './validate-address-modal';

const API_BASE_URL = {
	API_GATEWAY: config.apiGateway,
};

interface PaymentProps {
	orderQuantity: number;
	taxRate: any;
	taxRateLoaded: boolean;
	products: any[];
	isTaxLoading: boolean;
	paymentInputs: any;
	isCaptchaValid: boolean;
	createOrder: (payload: { callback: (order: any) => void; order: any }) => void;
	getTaxRate: (payload: { callback: () => void; taxPayload: any }) => void;
	validateAddress: (payload: { callback: (isOpen: boolean) => void; taxPayload: any }) => void;
	clearTaxRate: () => void;
	setSnackbarMessage: (payload: { message: string; type: string; snackbarAutohide?: number }) => void;
	getCcToken: (payload: any) => void;
	setPaymentInputs: (payload: any) => void;
	clearPaymentInputs: () => void;
	setIsCaptchaValid: (isValid: boolean) => void;
}

export const PaymentComponent: React.FC<PaymentProps> = props => {
	const classes = BasePageStyles();
	const history = useHistory();
	const NARCAN_PRODUCT_ID = 'NAR062702';
	const NARCAN_PRICE = parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const emailRegex = new RegExp(
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
	);
	const phoneRegex = new RegExp(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/);
	const zipRegex = new RegExp(/^\d{5}$/);
	const narcanProduct = {
		id: NARCAN_PRODUCT_ID,
		name: 'NARCAN Nasal Spray 4 mg',
		brand: 'Narcan',
		affiliation: 'Narcan Shop',
		price: NARCAN_PRICE,
	};
	const [orderQtyList, setOrderQtyList] = React.useState<number[]>([props.orderQuantity]);
	const orderQtyMax = props.orderQuantity;
	const orderQty = props.orderQuantity;
	const isDev = false;

	const initInputs = () => ({
		billingAddress: '',
		billingAddress2: '',
		billingCity: '',
		billingState: '',
		billingZip: '',
		name: '',
		businessName: '',
		email: '',
		phoneNumber: '',
	});

	const requiredInputs = () => {
		if (checked) {
			return ['name', 'email', 'phoneNumber'];
		}
		return ['billingAddress', 'billingCity', 'billingState', 'billingZip', 'name', 'email', 'phoneNumber'];
	};

	const [inputs, setInputs] = React.useState({ ...initInputs(), ...props.paymentInputs });
	const [checked, setChecked] = React.useState(props.paymentInputs?.checked || false);
	const [formErrors, setFormErrors] = React.useState<any>({});
	const [submitError, setSubmitError] = React.useState<string>('');
	const [enableSubmit, setEnableSubmit] = React.useState(false);
	const [termsChecked, setTermsChecked] = React.useState(false);
	const initialAddress: Address = {
		address: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
	};
	const [shippingAddresses, setShippingAddresses] = React.useState<Address[]>([initialAddress]);
	const [openAddressModal, setOpenAddressModal] = React.useState(false);

	React.useEffect(() => {
		console.log('Modal open state changed: ', openAddressModal);
	}, [openAddressModal]);

	/*  We'll need to mimic this, but lets just remove it for now.
	React.useEffect(() => {
		props.clearTaxRate();
		const isShipAddressFilled = (inputs.shippingAddress &&
			inputs.shippingCity &&
			inputs.shippingState &&
			zipRegex.test(inputs.shippingZip)) as boolean;
		setEnableSubmit(isShipAddressFilled);
	}, [inputs.shippingAddress, inputs.shippingAddress2, inputs.shippingCity, inputs.shippingState, inputs.shippingZip]);
	*/

	React.useEffect(() => {
		props.setPaymentInputs({ ...inputs, checked });
	}, [inputs, checked]);

	React.useEffect(() => {
		//Begin Checkout
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				tax: props.taxRate?.tax?.amount_to_collect || 0,
				items: [
					{
						index: 0,
						price: NARCAN_PRICE,
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
				],
				value: CalculateTotal(orderQty, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
				coupon: '',
				currency: 'USD',
			},
		});
	}, []);

	const routeChange = (path: string) => {
		history.push(path);
	};

	const handleSetInputs = (key: string, value: any) => {
		setInputs({ ...inputs, [key]: value });
		setFormErrors({ ...formErrors, [key]: null });
		setSubmitError('');
	};

	const formatString = (str: string) => {
		if (!str) return '';
		let formatted = str[0].toUpperCase() + str.slice(1);
		return formatted.replace(/([A-Z])/g, ' $1').trim();
	};

	const calculateTax = (
		street: string = shippingAddresses[0].address,
		city: string = shippingAddresses[0].city,
		state: string = shippingAddresses[0].state,
		zip: string = shippingAddresses[0].zip,
	) => {
		props.getTaxRate({
			callback: () => {},
			taxPayload: {
				toCity: city,
				toState: state,
				toStreet: street,
				toZip: zip,
				amount: CalculateTotal(orderQty, NARCAN_PRICE),
				lineItems: [
					{
						quantity: orderQty,
						id: NARCAN_PRODUCT_ID,
					},
				],
			},
		});
	};

	const onOrderCreated = (order: any) => {
		//Purchase
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'purchase',
			ecommerce: {
				tax: props.taxRate?.tax?.amount_to_collect || 0,
				items: [
					{
						index: 0,
						price: NARCAN_PRICE,
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
					},
				],
				value: CalculateTotal(orderQty, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
				coupon: '',
				currency: 'USD',
				shipping: 0,
				transaction_id: order.orderNumber,
			},
		});
		props.clearPaymentInputs();
		routeChange('/order');
	};

	//"transactionToken" is a misnomer, as the txn_id is what Elevon wants
	//to convert from authonly to completed tx later
	const generateOrderList = (transactionToken: string) => {
		const orderList = [];
		for (let i = 0; i < shippingAddresses.length; i++) {
			orderList.push({
				productId: NARCAN_PRODUCT_ID,
				shipAddress1: shippingAddresses[i].address,
				shipAddress2: shippingAddresses[i].address2,
				shipCity: shippingAddresses[i].city,
				shipState: shippingAddresses[i].state,
				shipZip: shippingAddresses[i].zip,
				billingAddress1: inputs.billingAddress,
				billingAddress2: inputs.billingAddress2,
				billingCity: inputs.billingCity,
				billingState: inputs.billingState,
				billingZip: inputs.billingZip,
				shipName: inputs.name,
				billingName: inputs.name,
				businessName: inputs.businessName,
				email: inputs.email,
				phone: inputs.phoneNumber,
				quantity: orderQtyList[i],
				shipFromWarehouse: props.taxRate?.shipFromWarehouse?.warehouse || '',
				transactionToken: transactionToken,
			});
		}
		return orderList;
	};

	const onPlaceOrder = async () => {
		const errors: any = {};
		for (let [key, value] of Object.entries(inputs)) {
			if (requiredInputs().includes(key) && value === '') {
				errors[key] = `${formatString(key)} is required`;
			}
		}

		if (!emailRegex.test(inputs.email.toLowerCase())) errors.email = 'Invalid email address';

		if (!phoneRegex.test(inputs.phoneNumber)) errors.phoneNumber = 'Invalid phone number';

		if (!checked && !zipRegex.test(inputs.billingZip)) errors.billingZip = 'Invalid zip code';

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		if (!props.taxRateLoaded) {
			props.setSnackbarMessage({ message: 'Tax rate not loaded', type: 'error' });
			return;
		}

		if (!NARCAN_PRICE) {
			props.setSnackbarMessage({ message: 'Product not loaded, please refresh page', type: 'error' });
			return;
		}

		const order = {
			productId: NARCAN_PRODUCT_ID,
			shipAddress1: inputs.shippingAddress,
			shipAddress2: inputs.shippingAddress2,
			shipCity: inputs.shippingCity,
			shipState: inputs.shippingState,
			shipZip: inputs.shippingZip,
			billingAddress1: checked ? inputs.shippingAddress : inputs.billingAddress,
			billingAddress2: checked ? inputs.shippingAddress2 : inputs.billingAddress2,
			billingCity: checked ? inputs.shippingCity : inputs.billingCity,
			billingState: checked ? inputs.shippingState : inputs.billingState,
			billingZip: checked ? inputs.shippingZip : inputs.billingZip,
			shipName: inputs.name,
			billingName: inputs.name,
			businessName: inputs.businessName,
			email: inputs.email,
			phone: inputs.phoneNumber,
			quantity: orderQty,
			shipFromWarehouse: props.taxRate?.shipFromWarehouse?.warehouse || '',
			transactionToken: undefined,
		};

		//Add Payment Info
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'add_payment_info',
			ecommerce: {
				tax: props.taxRate?.tax?.amount_to_collect || 0,
				items: [
					{
						index: 0,
						price: NARCAN_PRICE,
						coupon: '',
						item_id: NARCAN_PRODUCT_ID,
						discount: 0,
						quantity: orderQty,
						item_name: 'NARCAN Nasal Spray 4 mg',
						item_brand: 'Narcan',
						affiliation: 'Narcan Shop',
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
				],
				value: CalculateTotal(orderQty, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
				coupon: '',
				currency: 'USD',
			},
		});

		if (config.isAutomatedProcess) {
			try {
				//1) get the auth token from elevon which allows us to open the Lightbox payment pop-up
				const ccToken = await Api.post(`${API_BASE_URL.API_GATEWAY}/ccPaymentToken`, {
					amount: CalculateTotal(orderQty, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
					ssl_avs_address: inputs.billingAddress,
					ssl_avs_zip: inputs.billingZip,
					ssl_vendor_id: 'SC963080',
				});

				console.log(`got Elevon token: ${JSON.stringify(ccToken?.data)}`);

				if (ccToken?.data) {
					//2) open popup for user to input CC info for preauth
					openLightbox(ccToken.data);
				}
			} catch (e) {
				console.error(`error getting Elevon token: ${JSON.stringify(e)}`);
			}
		} else {
			//Manual Process
			//TODO: This process is broken. Should remove all logic related to this flag
			// props.createOrder({ callback: () => routeChange('/order'), order: order });
		}

		function openLightbox(token: any) {
			const paymentFields = {
				ssl_txn_auth_token: token,
				ssl_amount: CalculateTotal(orderQty, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
				ssl_avs_address: inputs.billingAddress,
				ssl_avs_zip: inputs.billingZip,
				ssl_vendor_id: 'SC963080',
			};

			const createOrderMethod = props.createOrder;

			const callback = {
				onError: function (error: any) {
					console.error('error!!' + JSON.stringify(error));
					props.setSnackbarMessage({ message: `Payment Error: ${JSON.stringify(error)}`, type: 'error' });
					props.setIsCaptchaValid(false);
				},
				onCancelled: function () {
					console.log('cancelled!!');
					props.setIsCaptchaValid(false);
				},
				onDeclined: function (response: any) {
					console.log('declined!!' + JSON.stringify(response));
					props.setSnackbarMessage({
						message: `Payment Declined: ${response.ssl_result_message}`,
						type: 'error',
						snackbarAutohide: 30000,
					});
					props.setIsCaptchaValid(false);
				},
				onApproval: function (response: any) {
					console.log('APPROVAL!!' + JSON.stringify(response));
					console.log('with tx uid!!' + response.ssl_txn_id);

					//3) once user's CC preauth is approved by elevon and we store the ssl_txn_id
					// (mis-named as transactionToken), create the order on our backend
					createOrderMethod({ callback: onOrderCreated, order: generateOrderList(response.ssl_txn_id) });
				},
			};

			const w = window as any;
			w.PayWithConverge.open(paymentFields, callback);

			return false;
		}
	};

	const getPlaceOrderTooltip = () => {
		const addressValidationText = 'shipping address must be validated';
		const recaptchaText = 'reCaptcha must be completed';
		const termsCheckText = 'you must agree to our terms of use';
		let tooltipTexts = [];
		if (!props.taxRateLoaded) tooltipTexts.push(addressValidationText);
		if (!props.isCaptchaValid) tooltipTexts.push(recaptchaText);
		if (!termsChecked) tooltipTexts.push(termsCheckText);
		if (tooltipTexts.length > 0) {
			tooltipTexts[0] = tooltipTexts[0].charAt(0).toUpperCase() + tooltipTexts[0].slice(1);
			tooltipTexts.forEach((text: string, index: number) => {
				if (index === 0) return;
				let newText = ', ';
				if (index === tooltipTexts.length - 1) newText += 'and ';
				tooltipTexts[index] = newText + text;
			});
			tooltipTexts.push(' before placing order.');
		}
		return tooltipTexts.join('');
	};

	const billingAddressCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3', display: 'inherit', alignItems: 'center' }}>
					<h3>Billing Address</h3>
				</Box>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress : inputs.billingAddress}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress', event.target.value);
					}}
					error={formErrors.billingAddress ? true : false}
					helperText={formErrors.billingAddress ? formErrors.billingAddress : ''}
					disabled={checked}
					required
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={checked ? inputs.shippingAddress2 : inputs.billingAddress2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingAddress2', event.target.value);
					}}
					disabled={checked}
				/>
				<TextField
					label="City"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '1' }}
					value={checked ? inputs.shippingCity : inputs.billingCity}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingCity', event.target.value);
					}}
					error={formErrors.billingCity ? true : false}
					helperText={formErrors.billingCity ? formErrors.billingCity : ''}
					disabled={checked}
					required
				/>
				<Autocomplete
					disablePortal
					options={dataList.StateCodes}
					getOptionLabel={(option: any) => `${option.value}(${option.label})`}
					value={
						inputs.billingState ? dataList.StateCodes.find((code: any) => code.label === inputs.billingState) : null
					}
					onChange={(event, newValue) => {
						handleSetInputs('billingState', newValue?.label || '');
					}}
					disabled={checked}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							label="State"
							error={formErrors.billingState ? true : false}
							helperText={formErrors.billingState || ''}
							required
						/>
					)}
				/>
				<TextField
					label="Zip Code"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: '3' }}
					value={checked ? inputs.shippingZip : inputs.billingZip}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('billingZip', event.target.value);
					}}
					error={formErrors.billingZip ? true : false}
					helperText={formErrors.billingZip ? formErrors.billingZip : ''}
					disabled={checked}
					required
					InputProps={{
						inputComponent: ZipCodeInput as any,
					}}
				/>
			</Paper>
		);
	};

	const customerInfoCard = () => {
		return (
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 3' }}>
					<h3 style={{ marginTop: '8px' }}>Contact Information</h3>
				</Box>
				<TextField
					label="Full Name"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: 'span 3' }}
					value={inputs.name}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('name', event.target.value);
					}}
					error={formErrors.name ? true : false}
					helperText={formErrors.name ? formErrors.name : ''}
					required
				/>
				<TextField
					label="Business Name"
					variant="outlined"
					sx={{ gridRow: '3', gridColumn: 'span 3' }}
					value={inputs.businessName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('businessName', event.target.value);
					}}
				/>
				<TextField
					label="Email"
					variant="outlined"
					sx={{ gridRow: '4', gridColumn: 'span 3' }}
					value={inputs.email}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('email', event.target.value);
					}}
					error={formErrors.email ? true : false}
					helperText={formErrors.email ? formErrors.email : ''}
					required
				/>
				<TextField
					label="Phone Number"
					variant="outlined"
					sx={{ gridRow: '5', gridColumn: 'span 3' }}
					value={inputs.phoneNumber}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSetInputs('phoneNumber', event.target.value);
					}}
					error={formErrors.phoneNumber ? true : false}
					helperText={formErrors.phoneNumber ? formErrors.phoneNumber : ''}
					required
					InputProps={{
						inputComponent: PhoneNumberInput as any,
					}}
				/>
			</Paper>
		);
	};

	const orderDetailsCard = () => {
		return (
			<>
				{/* "<>" is needed so the new "Grid" will not inherit, but instead be a new root */}
				<Grid container spacing={3}>
					<Grid xs={12} md={6}>
						<Box className={classes.paymentDetails}>
							<h3>Order Details</h3>
							<b>NARCAN&reg; Nasal Spray</b>
							<ul>
								<li>
									<p className={'paymentDetailsLabel'}>
										Qty {orderQty} x ${NARCAN_PRICE}:
									</p>
									<p className={'paymentDetailsData'}>
										${FormatCurrency(CalculateTotal(props.orderQuantity, NARCAN_PRICE))}
									</p>
								</li>
								<li>
									<p className={'paymentDetailsLabel'}>Estimated Tax:</p>
									<p className={'paymentDetailsData'}>
										{props.isTaxLoading ? (
											<CircularProgress style={{ color: globalColors.NARCAN_GRAY }} size={15} />
										) : props.taxRateLoaded ? (
											`$${FormatCurrency(props.taxRate?.tax?.amount_to_collect || 0)}`
										) : (
											'TBD'
										)}
									</p>
								</li>
								<li>
									<p className={'paymentDetailsLabel'}>Shipping:</p>
									<p className={'paymentDetailsData'}>
										<b style={{ color: '#E40572' }}>FREE</b>
									</p>
								</li>
								<li>
									<p className={'paymentDetailsLabel'}>Total:</p>
									<p className={'paymentDetailsData'}>
										<b>
											$
											{FormatCurrency(
												CalculateTotal(props.orderQuantity, NARCAN_PRICE, props.taxRate?.tax?.amount_to_collect || 0),
											)}
										</b>
									</p>
								</li>
							</ul>
						</Box>
					</Grid>
					<Grid xs={12} md={6}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<ReactRecaptchaConnected />
						</Box>
						<FormControlLabel
							label={
								<span>
									By checking this box, you are agreeing to our{' '}
									<a href={`${config.wordpressUrl}/terms-of-use`}>Terms of Use</a>
								</span>
							}
							className={classes.termsCheck}
							control={
								<Checkbox
									checked={termsChecked}
									onChange={(event, isChecked) => {
										setTermsChecked(isChecked);
									}}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Tooltip title={getPlaceOrderTooltip()} arrow>
								<span>
									<Button
										onClick={onPlaceOrder}
										disabled={!props.taxRateLoaded || !props.isCaptchaValid || !termsChecked}
										variant="contained"
										size="large"
									>
										Place Order
									</Button>
								</span>
							</Tooltip>
							{isDev && (
								<Button
									onClick={() => {
										console.log('Shipping List: ', shippingAddresses);
										console.log('Order List', generateOrderList('test-token'));
									}}
									variant="contained"
									size="large"
								>
									Test
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			</>
		);
	};

	const setSingleShippingAddress = (index: number, address: Address) => {
		const newAddresses = [...shippingAddresses];
		newAddresses[index] = address;
		setShippingAddresses(newAddresses);
	};

	const handleAddShippingAddress = () => {
		const newList = [...shippingAddresses];
		newList.push(initialAddress);
		const newQtyList = [...orderQtyList];
		newQtyList.push(1);
		setOrderQtyList(newQtyList);
		setShippingAddresses(newList);
	};

	const handleDeleteShippingAddress = (index: number) => {
		const newList = [...shippingAddresses];
		newList.splice(index, 1);
		setShippingAddresses(newList);

		const newQtyList = [...orderQtyList];
		newQtyList.splice(index, 1);
		if (newQtyList.length === 1) {
			newQtyList[0] = orderQtyMax;
		}
		setOrderQtyList(newQtyList);
	};

	const sumQty = () => {
		let sum = 0;
		for (let i = 0; i < orderQtyList.length; i++) {
			sum += orderQtyList[i];
		}
		return sum;
	};

	const handleOrderQtyChangeAtAddress = (addressIndex: number, newQty: number) => {
		const newList = [...orderQtyList];
		newList[addressIndex] = newQty;
		setOrderQtyList(newList);
	};

	const handleCloseModal = () => {
		console.log('Handling closing modal');
		setOpenAddressModal(false);
	};
	const handleOpenModal = () => {
		console.log('Handling open modal');
		setOpenAddressModal(true);
	};

	return (
		<Grid container columnSpacing={2} rowSpacing={4} xs={12} md={8}>
			<Grid xs={12}>
				<h1>Checkout</h1>
			</Grid>
			<Grid xs={12} md={6}>
				{customerInfoCard()}
			</Grid>
			<Grid xs={12} md={6}>
				{billingAddressCard()}
			</Grid>

			{shippingAddresses.map((currentAddress, index) => (
				<Grid xs={12} key={uuidv4()}>
					<ShippingAddressComponent
						key={uuidv4()}
						listIndex={index}
						listLengthIsGreatThanTwo={shippingAddresses.length > 1}
						paymentInputs={props.paymentInputs}
						orderQuantity={orderQtyList[index]}
						setOrderQuantity={(newQty: number) => {
							handleOrderQtyChangeAtAddress(index, newQty);
						}}
						enableSubmitOrder={enableSubmit}
						setEnableSubmitOrder={setEnableSubmit}
						product={narcanProduct}
						shippingAddress={currentAddress}
						setShippingAddress={(newAddress: Address) => {
							setSingleShippingAddress(index, newAddress);
						}}
						deleteShippingAddress={() => {
							handleDeleteShippingAddress(index);
						}}
						billingAddress={{
							address: inputs.billingAddress,
							address2: inputs.billingAddress2,
							city: inputs.billingCity,
							state: inputs.billingState,
							zip: inputs.billingZip,
						}}
						openValidationModal={handleOpenModal}
					/>
				</Grid>
			))}
			<Grid xs={12}>
				<Grid container justifyContent="flex-end">
					<Button onClick={handleAddShippingAddress} disabled={orderQtyMax < 13}>
						Add Address
					</Button>
				</Grid>
			</Grid>
			<Grid xs={12}>{orderDetailsCard()}</Grid>
			<Grid xs={12}>
				<Grid container justifyContent="flex-end">
					{shippingAddresses.length > 1 && (
						<>
							<Typography variant="body1">
								{sumQty()} of {orderQtyMax} allocated
							</Typography>
						</>
					)}
				</Grid>
				<Grid container justifyContent="flex-end">
					{sumQty() < orderQtyMax && (
						<Typography variant="body1" style={{ color: 'red' }}>
							All items must be allocated to a shipping address
						</Typography>
					)}
					{sumQty() > orderQtyMax && (
						<Typography variant="body1" style={{ color: 'red' }}>
							You can only allocate the amount ordered
						</Typography>
					)}
				</Grid>
			</Grid>
			<Modal
				open={openAddressModal}
				aria-labelledby="validate-address-modal"
				aria-describedby="Allows the user to check address validation."
			>
				<ValidateAddressModal closeModal={handleCloseModal} onSelect={calculateTax} />
			</Modal>
		</Grid>
	);
};
