import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Paper, Stack, Step, StepIconProps, StepLabel, Stepper, TextField, styled } from '@mui/material';
import moment from 'moment';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';
import { FormatCurrency } from '../../hooks/functions/FormatCurrency';
import config from '../../config';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

interface OrderProps {
	orderDetails: any
	products: any[]
	getOrderDetails: (payload: any) => void
	setSnackbarMessage: (payload: {message: string, type: string}) => void
}

export const OrderComponent: React.FC<OrderProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 
	const NARCAN_PRODUCT_ID = 'NAR062702'
	const NARCAN_PRICE = parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const steps = [ 'ORDER RECEIVED', 'PROCESSING', 'SHIPPED'];
	const [inputValue, setInputValue] = React.useState('');

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 22,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				`linear-gradient( 95deg,${globalColors.NARCAN_BRIGHT_PINK} 0%,${globalColors.NARCAN_PINK} 100%)`,
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				`linear-gradient( 95deg,${globalColors.NARCAN_BRIGHT_PINK} 0%,${globalColors.NARCAN_PINK} 100%)`,
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1,
		},
	}));

	const ColorlibStepIconRoot = styled('div')<{
		ownerState: { completed?: boolean; active?: boolean };
	}>(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			backgroundImage:
			`linear-gradient( 136deg, ${globalColors.NARCAN_BRIGHT_PINK} 0%, ${globalColors.NARCAN_BRIGHT_PINK} 50%, ${globalColors.NARCAN_PINK} 100%)`,
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		}),
		...(ownerState.completed && {
			backgroundImage:
			`linear-gradient( 136deg, ${globalColors.NARCAN_BRIGHT_PINK} 0%, ${globalColors.NARCAN_BRIGHT_PINK} 50%, ${globalColors.NARCAN_PINK} 100%)`,
		}),
	}));

	function ColorlibStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;
		
		const icons: { [index: string]: React.ReactElement } = {
			1: <CheckCircleOutlineIcon />,
			2: <ReceiptIcon />,
			3: <LocalShippingIcon />,
		};
		
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	const onTrackOrderClicked = () => {
		const filter: any = {where: {}}
		if(config.isAutomatedProcess) {filter.where.orderNumber = inputValue}
		else {filter.where.poNumber = inputValue}
		props.getOrderDetails({callback: () => routeChange(`/order`), filter})
	}

	const renderOrderDetails = () => {
		return (
			<Grid container item textAlign={'center'} xs={12} md={8}>
				<Grid item xs={12}>
					<h1>Thank you for your order!</h1>
					<h2>{config.isAutomatedProcess ? 
						`An email confirmation has been sent to ${props.orderDetails.email}!` : 
						`Thank you for placing your order. A member of our customer support team will promptly contact you to finalize and confirm the details of your order.`
					}</h2>
				</Grid>
				<Grid item xs={12}>
					<Stack sx={{ width: '100%' }} spacing={4}>
						<Stepper alternativeLabel activeStep={props.orderDetails.trackingNumber ? 2 : 1} connector={<ColorlibConnector />}>
							{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
							))}
						</Stepper>
					</Stack>
				</Grid>
				<Grid container justifyContent={'space-evenly'}>
					<Paper className={classes.orderDetailsPaper} elevation={1} component={Grid} container item xs={12} md={5}>
						<Grid item xs={12}><h3>Order Details</h3></Grid>
						<Grid item xs={12}><p>Order Date: {moment(props.orderDetails.createdAt).format('MM-DD-YYYY')}</p></Grid>
						<Grid item xs={12}><p>Order Number: {config.isAutomatedProcess ? props.orderDetails.orderNumber : props.orderDetails.poNumber}</p></Grid>
						<Grid item xs={12}><p>Order Total: ${FormatCurrency( CalculateTotal(props.orderDetails.quantity, NARCAN_PRICE, props.orderDetails.tax) )}</p></Grid>
					</Paper>
					<Paper className={classes.orderDetailsPaper} elevation={1} component={Grid} container item xs={12} md={5}>
						<Grid item xs={12}><h3>Delivery Details</h3></Grid>
						<Grid item xs={12}>
							<p>{props.orderDetails.shipName}</p>
							<p>{props.orderDetails.shipAddress1}</p>
							<p>{props.orderDetails.shipAddress2}</p>
							<p>{props.orderDetails.shipCity}, {props.orderDetails.shipState} {props.orderDetails.shipZip}</p>
						</Grid>
						<Grid item xs={12}>
							{props.orderDetails.trackingNumber ? 
								<h4><a target="_BLANK" href={`https://www.fedex.com/fedextrack/?trknbr=${props.orderDetails.trackingNumber}`}>Track Shipment</a></h4>
							:
								<h4>You’ll receive an email with tracking information when your order ships.</h4>
							}
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<h2>Thank you for choosing FFF Enterprises! {!props.orderDetails.trackingNumber && `Please allow 3-5 business days for delivery.`}</h2>
				</Grid>
				<Grid item xs={12} >
					<Button variant="contained" size='large'
						onClick={() => window.location.href = `${config.wordpressUrl}/`}
					>Learn More About NARCAN&reg; Nasal Spray</Button>
				</Grid>
			</Grid>
		)
	}

	const renderTrackingForm = () => {
		return (
			<Grid container item textAlign={'center'} xs={12} md={8}>
				<Grid item xs={12}>
					<h1>Track Your Order</h1><br/>
				</Grid>
				<Grid item xs={12}>
					<form className={classes.trackingForm} noValidate autoComplete="off">
						<TextField className={classes.trackingInput} id="outlined-basic" label="Order Number" variant="outlined" 
							value={inputValue} 
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setInputValue(event.target.value);
							}}
						/>
						<Button
							onClick={onTrackOrderClicked}
							className={classes.trackingInput}
							variant="contained"
							size='large'
						>Track</Button>
					</form>
				</Grid>
			</Grid>
		)
	}

	return <>{props.orderDetails?.orderNumber ? renderOrderDetails() : renderTrackingForm()}</>
}