import * as React from 'react';
import { BasePageStyles } from '../../hooks/styles';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Theme,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ZipCodeInput from '../../components/zipCodeInput';
import dataList from '../../constants/dataList';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NumberInput from '../../components/numberPicker';
import { CalculateTotal } from '../../hooks/functions/CalculateTotal';
import { Address } from './address.type';
import { Product } from '../product/product.type';
import { connect } from 'react-redux';
import { OrderActions } from '../../redux/actions';
export interface ShippingAddressProps {
	listIndex: number;
	listLengthIsGreatThanTwo: boolean;
	paymentInputs: any;
	orderQuantity: number;
	setOrderQuantity: (qty: number) => void;
	enableSubmitOrder: boolean;
	setEnableSubmitOrder: (enableIt: boolean) => void;
	taxRate?: any;
	taxRateLoaded?: boolean;
	getTaxRate?: (obj: any) => void;
	product: Product;
	validateAddress: (payload: { callback: (isOpen: boolean) => void; taxPayload: any }) => void;
	shippingAddress: Address;
	setShippingAddress: (shippingAddress: Address) => void;
	deleteShippingAddress: () => void;
	billingAddress: Address;
	openValidationModal: () => void;
}

const ShippingAddressComponent: React.FC<ShippingAddressProps> = props => {
	const classes = BasePageStyles();
	const zipRegex = new RegExp(/^\d{5}$/);
	const isFirstListItem = props.listIndex === 0;
	const initialAddress: Address = {
		address: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
	};

	const [tooltipTitle, setTooltipTitle] = React.useState('Min 1');
	const isFirstRender = React.useRef(true);
	const [total, setTotal] = React.useState(CalculateTotal(props.orderQuantity, props.product.price));
	const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const [shippingAddressErrors, setShippingAddressErrors] = React.useState<Address>({ ...initialAddress });
	const [hasShippingAddressError, setHasShippingAddressError] = React.useState<boolean>(true);
	const [isFirstSameAsBilling, setIsFirstSameAsBilling] = React.useState<boolean>(false);
	const [shipping, setShipping] = React.useState<Address>({ ...props.shippingAddress });

	const isMounted = React.useRef(false);

	// useEffect to track component mount and unmount
	React.useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const isTooltipOpen = () => {
		return !isFirstRender.current && (props.orderQuantity === 1 || props.orderQuantity === 2250);
	};

	const onQuantityChange = (newQty: number) => {
		if (newQty < 1) newQty = 1;
		if (newQty > 2250) newQty = 2250;
		props.setShippingAddress({ ...shipping });
		props.setOrderQuantity(newQty);
		setTotal(CalculateTotal(newQty, props.product.price));
		if (newQty === 1) setTooltipTitle('Min 1');
		else if (newQty === 2250) setTooltipTitle('Max 2250');
		if (isFirstRender.current) isFirstRender.current = false;
	};

	const handleAddressValidationComplete = (success: boolean) => {
		console.log('Handling address validation complete with param: ', success);
		props.openValidationModal();
	};

	const validateAddress = () => {
		props.setShippingAddress({ ...shipping });

		//Add Shipping Info
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'add_shipping_info',
			ecommerce: {
				tax: props.taxRate?.tax?.amount_to_collect || 0,
				items: [
					{
						index: 0,
						price: props.product.price,
						coupon: '',
						item_id: props.product.id,
						discount: 0,
						quantity: props.orderQuantity,
						item_name: props.product.name,
						item_brand: props.product.brand,
						affiliation: props.product.affiliation,
						item_variant: '',
						item_category: '',
						item_list_name: '',
					},
				],
				value: CalculateTotal(props.orderQuantity, props.product.price, props.taxRate?.tax?.amount_to_collect || 0),
				coupon: '',
				currency: 'USD',
			},
		});

		props.validateAddress({
			callback: handleAddressValidationComplete,
			taxPayload: {
				toCity: shipping.city,
				toState: shipping.state,
				toStreet: shipping.address,
				toZip: shipping.zip,
			},
		});
	};

	const checkIfAddressHasErrors = (newShippingAddressErrors: Address) => {
		for (const [key, value] of Object.entries(newShippingAddressErrors)) {
			if (value !== '') {
				setHasShippingAddressError(true);
				return;
			}
		}
		// No Error detected
		if (hasShippingAddressError) {
			setHasShippingAddressError(false);
		}
	};

	const handleAddressChange = (key: keyof Address, newValue: string) => {
		const newAddress: Address = { ...shipping };
		newAddress[key] = newValue;

		const newErrors = { ...shippingAddressErrors };

		switch (key) {
			case 'address':
				if (newValue === '') {
					newErrors[key] = 'Address Line 1 is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'city':
				if (newValue === '') {
					newErrors[key] = 'City is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'state':
				if (newValue === '') {
					newErrors[key] = 'State is required';
				} else {
					newErrors[key] = '';
				}
				break;
			case 'zip':
				if (newValue === '') {
					newErrors[key] = 'ZIP Code is required';
				} else if (!zipRegex.test(newValue)) {
					newErrors[key] = 'ZIP Code is invalid';
				} else {
					newErrors[key] = '';
				}
				break;
			default:
			// optional input.  No possible errors
		}
		setShippingAddressErrors(newErrors);
		checkIfAddressHasErrors(newErrors);

		setShipping(newAddress);
	};

	const handleSameAsBillingChange = () => {
		if (isFirstSameAsBilling) {
			setIsFirstSameAsBilling(false);
			setShipping({ ...props.shippingAddress });
		} else {
			setIsFirstSameAsBilling(true);
			setShipping({ ...props.billingAddress });
		}
	};

	return (
		<>
			<Paper
				className={classes.paymentCard}
				elevation={1}
				sx={{
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: 'auto',
				}}
			>
				<Box sx={{ gridRow: '1', gridColumn: 'span 2' }} style={{ height: '30px' }}>
					<Grid container>
						{!isFirstListItem && (
							<Grid xs={1}>
								<IconButton aria-label="delete" onClick={props.deleteShippingAddress}>
									<DeleteIcon />
								</IconButton>
							</Grid>
						)}
						<Grid xs={5} lg={3}>
							<h3>Shipping Address</h3>
						</Grid>
						{isFirstListItem && (
							<Grid xsOffset={1} xs={6} lgOffset={3}>
								<FormControlLabel
									control={
										<Checkbox
											inputProps={{ 'aria-label': 'controlled' }}
											size="small"
											checked={isFirstSameAsBilling}
											onChange={handleSameAsBillingChange}
										/>
									}
									label="Same as Billing Address"
								/>
							</Grid>
						)}
					</Grid>
				</Box>
				<TextField
					label="Address Line 1"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: '1' }}
					value={shipping.address}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleAddressChange('address', event.target.value);
					}}
					error={shippingAddressErrors.address ? true : false}
					helperText={shippingAddressErrors.address ? shippingAddressErrors.address : ''}
					required
					disabled={isFirstSameAsBilling}
				/>
				<TextField
					label="Address Line 2"
					variant="outlined"
					sx={{ gridRow: '2', gridColumn: '2' }}
					value={shipping.address2}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleAddressChange('address2', event.target.value);
					}}
					disabled={isFirstSameAsBilling}
				/>
				<Box sx={{ gridRow: '3', gridColumn: '1' }}>
					<Grid container spacing={1}>
						<Grid xs={4}>
							<TextField
								label="City"
								variant="outlined"
								value={shipping.city}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleAddressChange('city', event.target.value);
								}}
								error={shippingAddressErrors.city ? true : false}
								helperText={shippingAddressErrors.city ? shippingAddressErrors.city : ''}
								required
								disabled={isFirstSameAsBilling}
							/>
						</Grid>
						<Grid xs={4}>
							<Autocomplete
								disablePortal
								options={dataList.StateCodes}
								getOptionLabel={(option: any) => `${option.value}(${option.label})`}
								value={shipping.state ? dataList.StateCodes.find((code: any) => code.label === shipping.state) : null}
								onChange={(event, newValue) => {
									handleAddressChange('state', newValue?.label || '');
								}}
								renderInput={params => (
									<TextField
										{...params}
										variant="outlined"
										label="State"
										error={shippingAddressErrors.state ? true : false}
										helperText={shippingAddressErrors.state || ''}
										required
									/>
								)}
								disabled={isFirstSameAsBilling}
							/>
						</Grid>
						<Grid xs={4}>
							<TextField
								label="Zip Code"
								variant="outlined"
								value={shipping.zip}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleAddressChange('zip', event.target.value);
								}}
								error={shippingAddressErrors.zip ? true : false}
								helperText={shippingAddressErrors.zip ? shippingAddressErrors.zip : ''}
								required
								InputProps={{
									inputComponent: ZipCodeInput as any,
								}}
								disabled={isFirstSameAsBilling}
							/>
						</Grid>
					</Grid>
				</Box>

				<Grid container spacing={1}>
					{props.listLengthIsGreatThanTwo && (
						<>
							<Grid lg={4} style={{ display: isXs ? 'none' : 'block' }}>
								{!isXs && <p>Qty to this Address:</p>}
							</Grid>
							<Grid md={6} lg={4}>
								<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
									<NumberInput
										aria-label="Quantity Input"
										min={1}
										max={2250}
										value={props.orderQuantity}
										onChange={(event, newValue) => {
											onQuantityChange(newValue || 0);
										}}
										onInputChange={event => {
											onQuantityChange(parseInt(event.target.value || '0'));
										}}
										endAdornment={
											<InputAdornment position="end" sx={{ marginLeft: '-35px', marginRight: '18px' }}>
												bx
											</InputAdornment>
										}
									/>
								</Tooltip>
							</Grid>
						</>
					)}
					{!props.listLengthIsGreatThanTwo && <Grid md={6} lg={8}></Grid>}
					<Grid md={6} lg={4}>
						<Tooltip
							title={hasShippingAddressError && 'Please finish filling out Shipping Address before validating.'}
							arrow
						>
							<Box sx={{ gridRow: '3', gridColumn: '2', display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									variant="contained"
									size="large"
									onClick={validateAddress}
									disabled={hasShippingAddressError || props.taxRateLoaded}
									startIcon={props.taxRateLoaded && <CheckCircleOutlineIcon sx={{ color: 'white' }} />}
									sx={{
										'&.Mui-disabled': {
											background: props.taxRateLoaded ? '#4caf50' : '#616161',
										},
									}}
								>
									{props.taxRateLoaded ? 'Validated' : 'Validate Address'}
								</Button>
							</Box>
						</Tooltip>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	taxRate: state.order.taxRate || 0,
	taxRateLoaded: state.order.taxRateLoaded || false,
	isTaxLoading: state.order.isTaxLoading,
	isCaptchaValid: state.captcha.isCaptchaValid || false,
});

const mapDispatchToProps = (dispatch: any) => ({
	getTaxRate: (payload: any) => dispatch(OrderActions.getTaxRate(payload)),
	validateAddress: (payload: any) => dispatch(OrderActions.validateAddress(payload)),
	getCcToken: (payload: any) => dispatch(OrderActions.getCcPaymentToken(payload)),
	clearTaxRate: () => dispatch(OrderActions.clearTaxRate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressComponent);
