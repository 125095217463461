import config from '../../config';

export const API_BASE_URL = {
  API_GATEWAY: config.apiGateway
}

export const ORDER = {
  ORDERS: `${API_BASE_URL.API_GATEWAY}/orders`,
  GET_TAX: `${API_BASE_URL.API_GATEWAY}/tax`,
  VALIDATE_ADDRESS: `${API_BASE_URL.API_GATEWAY}/tax/validateAddress`,
  PRODUCT: `${API_BASE_URL.API_GATEWAY}/products`,
  GET_CC_PAYMENT_TOKEN: `${API_BASE_URL.API_GATEWAY}/ccPaymentToken`,
}
