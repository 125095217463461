import { connect } from 'react-redux';
import { PaymentComponent } from './payment.component';
import { CaptchaActions, OrderActions, UIActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  orderQuantity: state.order.orderQuantity || 12,
  taxRate: state.order.taxRate || 0,
  taxRateLoaded: state.order.taxRateLoaded || false,
  products: state.order.products || [],
  isTaxLoading: state.order.isTaxLoading,
  paymentInputs: state.order.paymentInputs || {},
	isCaptchaValid: state.captcha.isCaptchaValid || false
});

const mapDispatchToProps = (dispatch: any) => ({
  createOrder: (payload: { callback: (order: any) => void; order: any }) => dispatch(OrderActions.createOrder(payload)),
  getTaxRate: (payload: any) => dispatch(OrderActions.getTaxRate(payload)),
  validateAddress: (payload: any) => dispatch(OrderActions.validateAddress(payload)),
  getCcToken: (payload: any) => dispatch(OrderActions.getCcPaymentToken(payload)),
  clearTaxRate: () => dispatch(OrderActions.clearTaxRate()),
  setSnackbarMessage: (payload: {message: string, type: string}) => dispatch(UIActions.setSnackbarMessage(payload)),
  setPaymentInputs: (payload: any) => dispatch(OrderActions.setPaymentInputs(payload)),
  clearPaymentInputs: () => dispatch(OrderActions.clearTaxRate()),
	setIsCaptchaValid: (isValid: boolean) => dispatch(CaptchaActions.setIsCaptchaValid(isValid)),
});

export const PaymentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentComponent);